import React, { useEffect } from 'react';
import { message } from 'antd';
import lodash from 'lodash';
import { GenericBulkUploadService } from 'src/components/common/GenericBulkUploadService';
import { AppMetadata } from '../constants';
import { IncomingActions, eventToApiFuncMap } from './utils';

const MessageHandler = ({ appName }) => {
  const metadata = AppMetadata[appName];

  useEffect(() => {
    window.addEventListener('message', handleIncomingMessage);
    return () => {
      window.removeEventListener('message', handleIncomingMessage);
    };
  }, []);

  const handleGenericBulkUpload = (uploadParams) => {
    try {
      if (!eventToApiFuncMap[uploadParams.event]) {
        message.error(`No corresponding value found for ${uploadParams.event}`);
        return;
      }
      GenericBulkUploadService.uploadData({
        heading: uploadParams.heading,
        data: uploadParams.data,
        apiCallFn: eventToApiFuncMap[uploadParams.event],
        getRequestBody: (arrData) => {
          return { data: arrData, ...uploadParams.requestBody };
        },
        failureArrayKeysToShow: uploadParams.failureArrayKeysToShow,
        dataHeaders: uploadParams.dataHeaders,
      });
    } catch (e) {
      message.error(e.toString());
    }
  };

  const handleIncomingMessage = (event) => {
    if (event.origin !== metadata.origin) {
      return;
    }
    let action: any = {};
    try {
      action = JSON.parse(event.data);
    } catch (e) {
      return;
    }
    if (action.type === IncomingActions.GENERIC_BULK_UPLOAD && action.applicationId === appName && !lodash.isEmpty(action.uploadParams)) {
      handleGenericBulkUpload(action.uploadParams);
    }
  };

  return null;
};

export default MessageHandler;