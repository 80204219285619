import { applicationType } from '../globals';
import { getViewControl } from '../utils/genericConfig/utils';
import { getSortByDateFilterMapping } from '../components/common/utils';
import { getKeyArrayFromObject } from '../utils/utils';
import store from '../store/appStore';
import * as moment from 'moment';
import { getAuthenticationHeaders } from '../api/utils';

const pagination = {
  resultPerPage: 'pagination.perPageCount',
  lastSortByKey: 'pagination.lastSortByKey',
  nextOrPrev: 'pagination.nextOrPrev',
  lastSortedColumnValue: {
    path: 'pagination',
    fn: (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  lastRowId: {
    path: 'pagination',
    fn: (value) => {
      return getLastRowId(value);
    },
  },
  pageNumber: 'pagination.currentPageNumber',
  paginationRecordList: 'pagination.paginationRecordList',
};

const sort = {
  sortBy: {
    path: 'sort.sortBy',
    fn: (value) => {
      return getSortByDateFilterMapping[value]
        ? getSortByDateFilterMapping[value]
        : value;
    },
  },
  descendingOrder: 'sort.descendingOrder',
};

const globalFilters = {
  timeFilters: [
    {
      fromDateString: 'globalAppliedFilters.startDate',
      toDateString: 'globalAppliedFilters.endDate',
      columnName: 'globalAppliedFilters.dateFilterType',
      maxDateRangeAllowed: 'globalAppliedFilters.dateFilterType',
    },
  ],
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
};

const bucketFilters = {
  statusList: 'appliedFilters.statusList',
  courierPartnerList: 'appliedFilters.courierPartnerList',
  referenceNumberListInclusive: 'appliedFilters.referenceNumberListInclusive',
  salesOrderNumberList: 'appliedFilters.so_number_search',
  destinationPincodeCityList: 'appliedFilters.destinationPincodeCityList',
  tagList: 'appliedFilters.tag',
  rescheduledReasons: 'appliedFilters.rescheduledReasons',
  rescheduledReasonsForCRM: 'appliedFilters.rescheduledReasonsForCRM',
  isFalseAttempt: 'appliedFilters.falseAttempt',
  isFalseDelivery: 'appliedFilters.isFalseDelivery',
  consignmentRating: 'appliedFilters.rating',
  checkCod: 'appliedFilters.checkCod',
  codList: 'appliedFilters.codList',
  undeliveredFeedbackPresent: 'appliedFilters.undeliveredFeedbackPresent',
  hasRemarks: 'appliedFilters.hasRemarks',
  nextSteps: 'appliedFilters.nextSteps',
  timeSinceLastScanList: 'appliedFilters.timeSinceLastScan',
  proximityList: 'appliedFilters.proximityList',
  orderTypeList: 'appliedFilters.orderTypeList',
  deliveryTypeList: 'appliedFilters.deliveryTypeList',
  purchasingGroup: 'appliedFilters.purchasingGroup',
  maxExpectedDistance: 'appliedFilters.maxExpectedDistance',
  maxExpectedPickupDistance: 'appliedFilters.maxExpectedPickupDistance',
  incoTermsList: 'appliedFilters.incoTermsList',
  orderBreached: 'appliedFilters.orderBreached',
  isPartialDelivery: 'appliedFilters.isPartialDelivery',
  consignmentTripReferenceId: 'appliedFilters.consignmentTripReferenceId',
  dotFilter: 'appliedFilters.dotFilter',
  customerIdList: {
    path: 'appliedFilters.customerIdList',
    fn: (value) => {
      return value ? value.map((x) => x.key) : [];
    },
  },
  bookingServiceTypeIdList: 'appliedFilters.bookingServiceTypeIdList',
  attemptCountList: {
    path: 'appliedFilters.attemptCountList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  consignmentType: {
    path: 'appliedFilters.consignmentType',
    fn: (value) => {
      return value ? (value === 'all' ? undefined : value) : undefined;
    },
  },
  consignmentEmailCountList: {
    path: 'appliedFilters.consignmentEmailCountList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  shipperNextSteps: {
    path: 'appliedFilters.shipperNextSteps',
    fn: (value) => {
      return value ? value : [];
    },
  },
  internalNDRList: {
    path: 'appliedFilters.internalNDRList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  destinationCityList: {
    path: 'appliedFilters.destinationCityList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  destinationStateList: {
    path: 'appliedFilters.destinationStateList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  destinationZoneList: {
    path: 'appliedFilters.destinationZoneList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  orderNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'orderNumber');
  },
  courierPartnerReferenceNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'awbNumber');
  },
  dayPartFilter: 'appliedFilters.dayPartFilter',
  consignmentStatus: 'appliedFilters.consignmentStatus',
  allocationSourceList: 'appliedFilters.allocationSourceList',
  timeSlot: 'appliedFilters.timeSlot',
  nonRegularOrders: 'appliedFilters.nonRegularOrders',
  storeType: 'appliedFilters.storeType',
  employeeType: 'appliedFilters.employeeType',
  workerType: 'appliedFilters.workerType',
  finalConsignmentStatus: 'appliedFilters.finalConsignmentStatus',
  storeHubIdList: {
    path: 'appliedFilters.storeHubId',
    fn: (value) => {
      return value ? value.map((x) => x.key) : [];
    },
  },
  isHandedOver3pl: 'appliedFilters.isHandedOver3pl',
  creationBeforeElevenAM: 'appliedFilters.creationBeforeElevenAM',
  creationAfterElevenAM: 'appliedFilters.creationAfterElevenAM',
  exceptionIdList: {
    path: 'appliedFilters.exceptionIdList',
    fn: (value) => {
      return value ? value.map((x) => x.key) : [];
    },
  },
  assignedRider: {
    path: 'appliedFilters.assignedRider',
    fn: (value) => {
      return value ? value.map((x) => x.key) : [];
    },
  },
  nextAttemptDate: {
    path: 'appliedFilters.nextAttemptDate',
    fn: (value) => {
      return getFormattedDate(value);
    },
  },
  deliveryTimeslot: {
    path: 'appliedFilters.deliveryTimeslot',
    fn: (value) => {
      return getFormattedTime(value);
    },
  },
  executionStatusList: 'appliedFilters.executionStatusList',
  isCNVDone: 'appliedFilters.isCNVDone',
  movementTypeList: 'appliedFilters.movementTypeList',
  legTypeList: 'appliedFilters.legTypeList',
  bagReferenceNumberList: 'appliedFilters.bagReferenceNumberList',
  isPaymentDone: 'appliedFilters.isPaymentDone',
  invoiceValue: 'appliedFilters.invoiceValue',
  numPieces: 'appliedFilters.numPieces',
  isAddressUpdated: 'appliedFilters.isAddressUpdated',
  destinationPincodeList: 'appliedFilters.destinationPincodeList',
  consigneeCodeList: 'appliedFilters.consigneeCodeList',
  consignorCodeList: 'appliedFilters.consignorCodeList',
  sapTripNumber: 'appliedFilters.sapTripNumber',
  nextHubIdList: {
    path: 'appliedFilters.nextHubIdList',
    fn: (value) => {
      return value?.map((x) => x.key) || [];
    },
  },
  destinationHubIdList: {
    path: 'appliedFilters.destinationHubIdList',
    fn: (value) => {
      return value?.map((x) => x.key) || [];
    },
  },
  pickupAttemptCountList: {
    path: 'appliedFilters.pickupAttemptCountList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  kiranaHubIdList: {
    path: 'appliedFilters.kiranaHubIdList',
    fn: (value) => {
      return value?.map((x) => x.key) || [];
    },
  },
  subBusinessIdList: {
    path : 'appliedFilters.subBusinessIdList',
    fn : (value) => {
      return value ? value : [];
    },
  },
  typeOfDeliveryList: {
    path: 'appliedFilters.typeOfDeliveryList',
    fn: (value) => {
      return value ? value : [];
    },
  },
  isPgiDone: 'appliedFilters.isPgiDone',
  customerReferenceNumberListInclusive:
    'appliedFilters.customerReferenceNumberListInclusive',
  constraintTagsList: 'appliedFilters.constraintTagsList',
  lastCallLogsStatus: 'appliedFilters.lastCallLogsStatus',
  syncedStatus: 'appliedFilters.syncedStatus',
  workerIdList: 'appliedFilters.workerIdList',
  areaList: 'appliedFilters.areaList',
  riderAssigned: 'appliedFilters.riderAssigned',
  assignedToRider: 'appliedFilters.assignedToRider',
  zoneAssigned: 'appliedFilters.zoneAssigned',
  tripAssigned: 'appliedFilters.tripAssigned',
  taskReferenceNumber: 'appliedFilters.taskReferenceNumber',
  referenceNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'referenceNumber');
  },
  senderPhoneNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'senderPhone');
  },
  destinationPhoneNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'destinationPhone');
  },
  customerReferenceNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'customerReferenceNumber');
  },
  customerCivilIdList: (iteratee) => {
    return getSearchObject(iteratee, 'customerCivilId');
  },
  manifestNumberList: (iteratee) => {
    return getSearchObject(iteratee, 'manifestNumber');
  },
  courierHandoverManifestNumber: 'appliedFilters.courierHandoverManifestNumber',
  courierPartnerReferenceNumber: 'appliedFilters.courierPartnerReferenceNumber',
};

const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};

const getHubIdList = (iteratee) => {
  return iteratee.viewType === 'domestic'
    ? []
    : iteratee.viewType === 'crm'
    ? iteratee.globalAppliedFilters.hubId || []
    : [iteratee.currHub.id];
};

const getFormattedDate = (value) => {
  let startDate = moment().format('YYYY-MM-DD');
  let endDate = moment().format('YYYY-MM-DD');
  if (value && value.length > 1) {
    startDate = value[0].format('YYYY-MM-DD');
    endDate = value[1].format('YYYY-MM-DD');
  }
  const obj = {
    fromDateString: startDate,
    toDateString: endDate,
  };
  return value && value.length > 1 ? obj : {};
};

const getFormattedTime = (value) => {
  let startTime = moment().format('hh:mm');
  let endTime = moment().format('hh:mm');
  if (value && value.length > 1) {
    startTime = value[0].format('hh:mm');
    endTime = value[1].format('hh:mm');
  }
  const obj = {
    fromTimeString: startTime,
    toTimeString: endTime,
  };
  return value && value.length > 1 ? obj : {};
};

const getSearchObject = (inp, param) => {
  if (inp.selectedRowKeys) {
    if (param === 'referenceNumber') {
      return inp.selectedRowKeys;
    }
    return undefined;
  }
  if (param === inp.globalAppliedFilters.searchType) {
    const searchValue = inp.globalAppliedFilters.searchValue;
    if (Array.isArray(searchValue) && searchValue.length > 0) {
      return searchValue;
    }
    const searchVal = searchValue.length > 0 ? [searchValue] : [];
    return searchVal;
  }
  return [];
};

const getBucketApiValue = (bucket) => {
  return bucket;
};

const requestModelGenericFilterSearch = {
  bucket: {
    path: 'bucket',
    fn: (value) => getBucketApiValue(value),
  },
  subBucket: 'subBucket',
  ...pagination,
  ...sort,
  ...bucketFilters,
  hubIdList: (iteratee) => {
    return getHubIdList(iteratee);
  },
};

const requestModelGenericFilter = {
  bucket: {
    path: 'bucket',
    fn: (value) => getBucketApiValue(value),
  },

  subBucket: 'subBucket',
  ...pagination,
  ...sort,
  ...globalFilters,
  ...bucketFilters,
};

const requestModelConsolidatedPlanningDownload = {
  ...globalFilters,
  ...bucketFilters,
  sortBy: {
    path: 'sortBy',
    fn: (value) => {
      return 'created_at' //hardcoding as planning/v1/get always use created_at
    },
  },
  bucket: 'bucket',
  subBucket: 'subBucket',
  requiredColumns: 'requiredColumns',
  childDownloadData: 'childDownloadData',
  type: 'appliedFilters.dumpType',
};

const requestModelGenericDownload = {
  ...globalFilters,
  ...bucketFilters,
  sortBy:'globalAppliedFilters.dateFilterType',
  bucket: 'bucket',
  subBucket: 'subBucket',
  requiredColumns: 'requiredColumns',
  childDownloadData: 'childDownloadData',
  type: 'appliedFilters.dumpType',
};

export {
  requestModelGenericFilter,
  requestModelGenericFilterSearch,
  requestModelGenericDownload,
  requestModelConsolidatedPlanningDownload,
};
