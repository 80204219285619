import { message } from 'antd';
import { GREEN_DEPLOYMENT_URLS } from '../globals';
import lodashKeys from 'lodash/keys';
import { FIREBASE_CONFIG, FIRESTORE_DB_ID } from 'src/globals';
const moment = require('moment');
import axios from 'axios';
import { getConsignmentsExtraData } from 'src/api/retailDashboard';
// do not remove; required for formatTimeSinceDate function
require('moment-duration-format');
export const handleXLSXFile = async (file, headers): Promise<any> => {
  // To lazy load xlsx module using dynamic import of webpack
  const XLSX = await import(
    /* webpackChunkName: "xlsx" */
    'xlsx');
  return new Promise((resolve, reject) => {
    // Added New Code to Support XLSX File Starts
    // define reader
    const reader = new FileReader();
    let result = [];
    let fileValue;
    // A handler for the load event (just defining it, not executing it right now)
    reader.onload = function (e) {
      fileValue = reader.result;
      const wb = XLSX.read(fileValue,
        {
          type: 'binary', codepage: 65001, dateNF: 'yyyy-mm-dd',
          cellDates: true,
        });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* sheet to json */
      /* array inside array without header */
      result = XLSX.utils.sheet_to_json(wb.Sheets[wsname], { header: headers, range: 1 });
      resolve(result);
    };
    reader.readAsBinaryString(file);
  });
};

export const handleXLSXWorkbook = async (file, headers:[]): Promise<any> => {
  // To lazy load xlsx module using dynamic import of webpack
  const XLSX = await import(
    /* webpackChunkName: "xlsx" */
    'xlsx');
  return new Promise((resolve, reject) => {
    // Added New Code to Support XLSX File Starts
    // define reader
    const reader = new FileReader();
    const result = [];
    let fileValue;
    // A handler for the load event (just defining it, not executing it right now)
    reader.onload = function (e) {
      fileValue = reader.result;
      const wb = XLSX.read(fileValue,
        {
          type: 'binary', codepage: 65001, dateNF: 'yyyy-mm-dd',
          cellDates: true,
        });
      /* sheet to json */
      /* array inside array without header */
      const wsNames  = wb.SheetNames;
      const result:any = [];
      for (let i = 0; i < wsNames.length; i = i + 1) {
        const sheet:any =
          XLSX.utils.sheet_to_json(wb.Sheets[wsNames[i]], { header: headers[i], range: 1 });
        result.push(sheet);
      }
      resolve(result);
    };
    reader.readAsBinaryString(file);
  });
};

export const handleXLSXFileWithoutMapping = async (file, firstRowAsHeader?: any): Promise<any> => {
  // To lazy load xlsx module using dynamic import of webpack
  const XLSX = await import(
    /* webpackChunkName: "xlsx" */
    'xlsx');
  return new Promise((resolve, reject) => {
    // Added New Code to Support XLSX File Starts
    // define reader
    const reader = new FileReader();
    let result = [];
    let fileValue;
    // A handler for the load event (just defining it, not executing it right now)
    reader.onload = function (e) {
      fileValue = reader.result;
      const data = new Uint8Array(fileValue);
      const wb = XLSX.read(data, {type: 'array', codepage: 65001});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* sheet to json */
      /* array inside array without header */
      result = XLSX.utils.sheet_to_json(wb.Sheets[wsname],  {
        header: firstRowAsHeader ? 0 : 1,
      });
      resolve(result);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const handleXLSXFileDateTime = async (file, headers ? : any, range = null): Promise<any> => {
  const XLSX = await import(
        'xlsx');
  return new Promise((resolve, reject) => {
      // Added New Code to Support XLSX File Starts
      // define reader
    const reader = new FileReader();
    let tempArray = [];
    let csvFile;
    const defaultRange = 1;
      // A handler for the load event (just defining it, not executing it right now)
    reader.onload =  (e) => {
      csvFile = reader.result;
      const wb = XLSX.read(csvFile, { type: 'binary', codepage: 65001, raw: true, cellNF: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // remove exponatial format in Excel for numbers
      Object.keys(ws).forEach((s) => {
        // If the type is number and there is value inside the cell and it is exponential notation
        if (ws[s].t === 'n' && ws[s].v && ws[s].w && ws[s].w.match(/[Ee]\+\d+$/)) {
                // Set the formatted string as the String equivalent of value,
                // so raw number is converted to raw string
          ws[s].w = String(ws[s].v);
          delete ws[s].z;
        }
      });
      const rangeVal = range === null || range === undefined ? defaultRange : range;

      tempArray = XLSX.utils.sheet_to_json(wb.Sheets[wsname],
        { header: headers, range: rangeVal, raw: false });

      resolve(tempArray);
    };
    reader.readAsBinaryString(file);
  });
};

export const handleXLSXWorkbookWithoutMapping = async (file,
  headerVal = 0, rangeVal = 0): Promise<any> => {
  // To lazy load xlsx module using dynamic import of webpack
  const XLSX = await import(
    /* webpackChunkName: "xlsx" */
    'xlsx');
  return new Promise((resolve, reject) => {
    // Added New Code to Support XLSX File Starts
    // define reader
    const reader = new FileReader();
    let fileValue;
    // A handler for the load event (just defining it, not executing it right now)
    reader.onload = function (e) {
      fileValue = reader.result;
      // check options: raw, dateTime etc.
      const wb = XLSX.read(fileValue,
        {
          type: 'binary', codepage: 65001, dateNF: 'yyyy-mm-dd',
          cellDates: true,
        });
      /* sheet to json */
      /* array inside array without header */
      const wsNames  = wb.SheetNames;
      const result:any = {};
      for (let i = 0; i < wsNames.length; i = i + 1) {
        const sheet:any =
          XLSX.utils.sheet_to_json(wb.Sheets[wsNames[i]], { header: headerVal, range: rangeVal });
        result[wsNames[i]] = sheet;
      }
      resolve(result);
    };
    reader.readAsBinaryString(file);
  });
};

export const timeoutPromise = (interval) => {
  return new Promise((resolve) => {
    setTimeout(resolve, interval);
  });
};

export function formatDateTime(dateTime) {
  return dateTime ? moment(dateTime).format('DD MMM YYYY, hh:mm A') : 'Not Available';
}

export function formatTimeSlot(dateTime1, dateTime2) {
  const hr1 = dateTime1? moment(dateTime1).format('hh A') : undefined;
  const hr2 = dateTime2? moment(dateTime2).format('hh A') : undefined;
  const time = hr1 && hr2 && (hr1 + ' - ' + hr2);
  const date = moment(dateTime1).format('DD MMM YYYY');
  return time ? time+'    '+date : undefined;
}

export function formatTime(time) {
  return time ? moment(time).format('hh:mm A') : '---';
}

export function formatTimeWithSeconds(time) {
  return time ? moment(time).format('hh:mm:ss A') : '---';
}

export function formatDate(date, nullOption = '---') {
  return date ? moment(date).format('DD MMM, YYYY') : nullOption;
}
export function customFormatDate(date, format, nullOption = '-') {
  return date ? moment(date).format(format) : nullOption;
}
export function getCurrentDate(){
  return moment().format('YYYY/MM/DD');
}
export function getDate(str){
  return moment(str).format('YYYY/MM/DD');
}
export function getPreviousDate(days){
  return moment().subtract(days, 'd').format('YYYY/MM/DD');
}
export function formatTimeSinceDate(time) {
  const formattedTime = moment.duration(time, 'milliseconds').format('d [days], h [hrs]');
  return time ? formattedTime : null;
}
export function getFirstDayOftheMonth(date){
  return date.clone().startOf('month').format('YYYY-MM-DD');
}
export function getLastDayOftheMonth(date){
  return date.clone().endOf('month').format('YYYY-MM-DD');
}
export function getDaysToCurrentDate(date){
  return moment().diff(moment(date),'days')+1;
}

export function getFormattedDate (value) {
  let startDate = moment().format('DD MMM, YYYY');
  let endDate = moment().format('DD MMM, YYYY');
  if (value && value.length > 1) {
    startDate = value[0].format('DD MMM, YYYY');
    endDate = value[1].format('DD MMM, YYYY');
  }
  const dateString = `${startDate} - ${endDate}`;
  return value && value.length > 1 ? dateString : '';
}

export function getFormattedTime (value) {
  let startTime = moment().format('hh:mm');
  let endTime = moment().format('hh:mm');
  if (value && value.length > 1) {
    startTime = value[0].format('hh:mm');
    endTime = value[1].format('hh:mm');
  }
  const timeString = `${startTime} - ${endTime}`;

  return value && value.length > 1 ? timeString : '';
}

export function convert24hto12h(timeString) {
  const hour = +timeString % 24;
  return (hour % 12 || 12) + (hour < 12 ? "AM" : "PM");
}

export function convertSecondsToHHMMSS(secNum: number) {
  if (!secNum && secNum !== 0) { return null; }
  let hours: number   = Math.floor(secNum / 3600);
  let minutes: number = Math.floor((secNum - (hours * 3600)) / 60);
  let seconds: number = secNum - (hours * 3600) - (minutes * 60);
  seconds = seconds > 0 ? Math.round(seconds) : seconds;

  return `${
    (hours < 10) ? `0${hours}` : hours
  }h ${
    (minutes < 10) ? `0${minutes}` : minutes
  }m ${
    (seconds < 10) ? `0${seconds}` : seconds
  }s`;
}

export function convertHHMMSSToSeconds(timeString: string) {
  if (!timeString) { return null; }
  if (timeString.split(':').length !== 3) { return null; }
  const hours: number   = +timeString.split(':')[0];
  const minutes: number = +timeString.split(':')[1];
  const seconds: number = +timeString.split(':')[2];

  return (hours * 3600) + (minutes * 60) + seconds;
}

export function chunkArray(arr = [], size) {
  const temparray: any = [];
  for (let i = 0, j = arr.length; i < j; i += size) {
    temparray.push(arr.slice(i, i + size));
  }
  return temparray;
}

export const consignmentStatus = {
  reachedathub: 'Reached at hub',
  outfordelivery: 'Out for delivery',
  delivered: 'Delivered',
  attempted: 'Attempted',
};

export function consignmentStatusPrettyNames(key) {
  const value = consignmentStatus[key];
  return value ? value : key;
}

export const consignmentTypes = {
  forward: 'Delivery',
  reverse: 'Pickup',
  pickupanddelivery: 'Pickup and Delivery',
  pickupanddeliveryreverse: 'Pickup and Delivery Reverse',
  replacement: 'Replacement',
  hyperlocal: 'Hyperlocal',
};
export const planningTypes = {
  single_consignment_pickup: 'Single Consignment Pickup',
  pickup: 'Pickup Request',
  delivery: 'Delivery',
  job_cod: 'Job COD',
  job_installation: 'Job Installation',
  job_maintenance: 'Job Maintenance',
  job_service: 'Job Service',
  job_inspection: 'Job Inspection',
  job_rectification: 'Job Rectification',
};

export const jobStatuses = {
  attempted: 'Attempted',
  cancelled: 'Cancelled',
  created: 'Created',
  started: 'Started',
  success: 'Completed'
};

const consignmentTypesExpress = {
  ...consignmentTypes,
  reverse: 'Reverse',
};

export const taskTypes = {
  single_consignment_pickup: 'Single Consignment Pickup',
  delivery: 'Delivery',
  store_consignment_pickup: 'Store Consignment Pickup',
  hubtohub: 'Hub to Hub',
  replacement: 'Replacement',
  rto: 'RTO',
  success: 'Success',
  attempted: 'Attempted',
  unresolved: 'Unresolved',
  created: 'Created',
  arrived: 'Arrived',
  started: 'Started',
  assigned_for_delivery: 'Assigned for Delivery',
};

export const jobTaskTypes = {
  pickup_and_delivery: 'Pickup and Delivery',
  cod_task: 'COD task',
  maintenance: 'Maintenance',
  inspection: 'Inspection',
  installation: 'Installation',
};

export const downloadFilters = {
  statusList: 'Status',
  customerCodes: 'Client Codes',
  hubCodes: 'Hubs',
  serviceTypes: 'Service Types',
  bookingServiceTypes: 'Booking Service Types',
  attemptCountList: 'Attempt Count',
  ratingList: 'Rating',
  consignmentRating: 'Rating',
  isFalseDelivery: 'False Delivery',
  referenceNumberList: 'Reference Number',
  senderPhoneNumberList: 'Sender Phone Number',
  courierPartnerList: 'Carrier',
  tagList: 'Tag',
  falseAttempt: 'False Attempt',
  undeliveredFeedbackPresent: 'Feedback Available',
  isFalseAttempt: 'False Attempt',
  nextSteps: 'Customer Next Steps',
  timeSinceLastScanList: 'Time since last scan',
  consignmentEmailCountList: 'Followup Count',
  sortBy : 'Sort By',
  descendingOrder : 'Descending Order',
  customerIdList: 'Customers',
  hubIdList: 'Hubs',
  bookingServiceTypeIdList: 'Booking Service Types',
  subBusinessIdList: 'Sub Business Id',
  movementType: 'Movement Type',
  isRto :'Movement Type',
  bucket: 'Bucket',
  subBucket: 'Sub bucket',
  isCod: 'COD Available',
  orderNumberList: 'Order Number',
  courierPartnerReferenceNumberList: 'AWB Number',
  destinationPhoneSearchList: 'Destination Phone Number',
  hasRemarks: 'Remarks',
  shipperNextSteps: 'Shipper Next Steps',
  internalNDRList: 'Internal NDR Reason',
  dayPartFilter: 'Day Part Filter',
  employeeType: 'Employee Type',
  workerType: 'Worker Type',
  finalConsignmentStatus: 'Order Status',
  nonRegularOrders: 'Order Type',
  timeSlot: 'Time Slot',
  orderTypeList : 'Order Type List',
  deliveryTypeList: 'Delivery Types',
  consignmentType : 'Consignment Type',
  proximityList : 'Proximity List',
  purchasingGroup :' Stock Transfer',
  hubCodeList: 'Hubs',
  requiredColumns : 'Required Columns',
  groupByParams : 'Group By',
  fromDate: 'From Date',
  toDate: 'To Date',
  rescheduledReasons: 'Rescheduled Reasons',
  scheduledDate: 'Scheduled Date',
  salesOrderNumberList: 'SO Number',
  storeHubCodes: 'Store Hubs',
  destinationPincodeCityList: 'Destination Pincode City',
  exceptionIdList: 'Exceptions',
  executionStatusList: 'Execution Status',
  reportType: 'Report Type',
  isClosurePending: 'Is Closure Pending',
  movementTypeList: 'Movement Type',
  legTypeList: 'CN Leg',
  isCNVDone: 'CNV Status',
  childDownloadData: 'Child Download Data',
  isPaymentDone: 'Payment Cleared',
  invoiceValue: 'Invoice Value',
  numPieces: 'Pieces',
  daysOfWeek: 'Days Of The Week',
  excludeDays: 'Exclude Days',
  destinationPincodeList: 'Destination Pincode',
  consigneeCodeList: 'Consignee Code',
  consignorCodeList: 'Consignor Code',
  nextHubIdList: 'Next Hub',
  destinationHubIdList: 'Destination Hub',
  constraintTagsList: 'Delivery Types',
  requestType: 'Request Type',
  hubCode: 'Hub',
  lastCallLogsStatus: 'Last Call Logs Status',
  checkCod: 'Is COD',
  rescheduledReasonsForCRM: 'Rescheduled Reasons',
  syncedStatus: 'Synced Status',
  nextAttemptDate: 'Next Attempt Date',
  pickupAttemptCountList: 'Pickup Attempts',
  bagReferenceNumberList: 'Bag Reference Number',
  customerReferenceNumberListInclusive: 'Customer Reference Number',
  referenceNumberListInclusive: 'Reference Number',
  riderAssigned: 'Rider Assigned',
  zoneAssigned: 'Zone Assigned',
  areaList: 'Area',
  creationBeforeElevenAM: 'Creation Time',
  tripAssigned: 'Trip Assigned',
  isPgiDone: 'Is PGI Done',
  sapTripNumber: 'Sap Trip Number',
  isHandedOver3pl: 'Handed Over 3pl',
  isAddressUpdated: 'Is Address Updated',
  customerCivilIdList: 'Customer Civil ID',
  customerReferenceNumberList: 'Customer Reference Number',
  destinationPhoneNumberList: 'Destination Phone Number',
  manifestNumberList: 'Manifest Number',
  assignedToRider: 'Assigned To Rider',
  workerIdList: 'Worker ID List',
  transactionTypeList: 'Transaction Type List',
  destinationCityList: 'Destination City',
  destinationStateList: 'Destination State',
  destinationZoneList: 'Destination Zone',
  deliveryTimeslot: 'Delivery Time Slot',
  maxExpectedDistance: 'Max Expected Distance',
  maxExpectedPickupDistance: 'Max Expected Distance Pickup',
  consignmentStatus: 'Order Status',
  allocationSourceList: 'Allocation Source',
  orderBreached: 'Order Breached',
  consignmentTripReferenceId: 'Trip ID',
  dotFilter: 'DOT (in mins)',
  isPartialDelivery: 'Is Partial Delivery',
  assignedRider: 'Assigned Rider',
  jobDetails: 'Job Details',
};
export const dateFilterTypes = {
  created_at: 'Creation Date',
  ewb_validity: 'EWB Validity Date',
  completion_time: 'Completion Date',
  ewb_creation: 'EWB Creation Date',
};

export function getFiltersPrettyName(key) {
  const value = downloadFilters[key];
  return value ? value : key;
};

const groupByParamsPrettyName = {
  hubId: 'Hub Code',
  paymentType: 'Payment Type',
  status: 'Status',
  customerId: 'Client Code',
  serviceTypeId: 'Service Type',
};

export const InvoiceTypes = {
  all: 'All',
  less_than_50k: 'Less than 50K',
  greater_or_equal_50k: 'Greater than equal to 50K',
};
export const pieces = {
  all: 'All',
  single: 'Single-Piece',
  multi: 'Multi-Piece',
};
export function getgroupByParamsPrettyName(key) {
  const value = groupByParamsPrettyName[key];
  return value ? value : key;
}
export function getConsignmentTypePrettyName(key, isExpress?: any) {
  const value = isExpress ? consignmentTypesExpress[key] : consignmentTypes[key];
  return value ? value : key;
}
export function getPlanningTypePrettyName(key) {
  const value = planningTypes[key];
  return value ? value : key;
}

export function getJobStatusPrettyName(key) {
  const value = jobStatuses[key];
  return value ? value : key;
}
export function getTaskTypePrettyName(key) {
  const value = taskTypes[key] || planningTypes[key];
  return value ? value : key;
}
export function getInvoiceValuePrettyNames(key) {
  const value = InvoiceTypes[key];
  return value ? value : key;
}
export function getPiecesValuePrettyNames(key) {
  const value = pieces[key];
  return value ? value : key;
}

export const elementOuterHeight = (el: HTMLElement) => {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);

  height += parseInt(style.marginTop || '0', 10) + parseInt(style.marginBottom || '0', 10);
  return height;
};

export const validateEmail = (email) => {
  // tslint:disable-next-line:max-line-length
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const defaultSorter = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a === b) {
    return 0;
  }
  return 1;
};
export const handleDownloadDump = async (fileResultArray, fileName='') => {
  const XLSX = await import(
    /* webpackChunkName: "xlsx" */
    'xlsx');

  /* make the worksheet */
  const ws = XLSX.utils.json_to_sheet(fileResultArray);

  /* add to workbook */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Download Dump');

  /* generate an XLSX file */
  XLSX.writeFile(wb, fileName ? fileName : `dump_js-${moment().format('DD-MMM-YYYY')}.xlsx`);
};

export const downloadFileData = (data, fileName, extensionIncluded: any = false) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}${extensionIncluded ? extensionIncluded : '.xlsx'}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFileDataWithString = (string, fileName, extensionIncluded: any = false) => {
  const bytes = new TextEncoder().encode(string);
  const blob = new Blob([bytes], { type: 'text/csv' });
  downloadFileData(blob, fileName, extensionIncluded);
};

export const downloadMultipleFileDataWithUrl = (
  urlData: { url: string, fileName?: string }[],
  extensionIncluded: any = false) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  for (let url of urlData) {
    link.href = url.url;
    const downloadAttributeToSet = `${url.fileName}${extensionIncluded ? extensionIncluded : '.xlsx'}`;
    link.setAttribute('download', downloadAttributeToSet);
    link.setAttribute('target', '_blank');
    link.click();
  }
  document.body.removeChild(link);
};

export const downloadFileDataWithUrl = (
  url,
  fileName,
  extensionIncluded: any = false,
  openInNewTab = false) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}${extensionIncluded ? extensionIncluded : '.xlsx'}`);
  link.setAttribute('target',openInNewTab? '_blank': '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function humanize(str) {
  return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, (m) => { return m.toUpperCase(); });
}

export const getUrlFromSVG = (svg) => {
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`;
};

export const getObjectKeysWithValueCount = (obj:Object = {}, keysToRemove ?: string[] ) => {
  if (!obj) {
    return 0;
  }
  const allKeys = keysToRemove ?
   Object.keys(obj).filter((key) => !keysToRemove.includes(key)) : Object.keys(obj);
  let count = 0;
  allKeys.forEach((y:any) => {
    const x = obj[y];
    if (
      !(((x instanceof Array) && x.length === 0) || // array with length 0
      ((x instanceof Object) && Object.keys(x).length === 0) || //  object with no keys
      (x === null || x === undefined))
     ) {
      count =  count + 1;
    }
  });
  return count;
};

export const getKeyArrayFromObject = (input) => {
  if (input && Array.isArray(input) && input.length) {
    return input.map(x => x.key);
  }
  return [];
};
export const escapeRegExp = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
export const removeArrayDuplicates = (arr) => {
  return arr.filter((item, pos) => arr.indexOf(item) === pos);
};

export const objectHasOwnProperty =
  (obj, key: string): boolean => ({}).hasOwnProperty.call(obj, key);

export const isBoolean = (val) => {
  return typeof val === 'boolean';
};
export const formatTimeTMS = (x) => {
  return x ?  moment(x).format('DD MMM, YYYY, HH:mm') : null;
};
export const formatBagCreationTime = (x) => {
  return x ? moment(x).format('Do MMMM YYYY, hh:mm A') : null;
};
export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};
export const isValidWeight = (val) => {
  if (Number.isInteger(val) || isFloat(val) ) {
    return true;
  }
  return false;
};
export function formatDateTMS(date, format?: String, nullOption = '---') {
  return date ? moment(date).format(format || 'L') : nullOption;
}
export function formatOnlyTimeTMS(date, format?: String, nullOption = '---') {
  return date ? moment(date).format(format || 'LT') : nullOption;
}
export const checkResponseFailures = (response) => (
  response.data && response.data.failures && response.data.failures.length > 0
);
export const isValidKey = (val) => {
  return val !== null && val !== undefined ? true : false;
};
export const getMomentObjectfromDate = (date) => {
  return moment(date, 'YYYY-MM-DD');
};
export const parseMinuteSeconds = (num) => {
  return Math.floor(num/60) + 'min' + ' ' + Math.floor(num%60)+ 'sec';
};
export const parseMinuteSecondsCustomFormat = (num) => {
  const secsVal = Math.floor(num%60);
  const formatSecsVal = secsVal < 10 ? ('0'+secsVal) : secsVal;
  return Math.floor(num/60) + '.' + formatSecsVal;
};
export const dateFormat = (date, format?) => {
  return date?.format(format || 'YYYY-MM-DD');
};
export const weekDay = (date) => {
  return moment(date).format('ddd').toString();
};
export const dayDate = (date) => {
  return moment(date).format('DD MMM').toString();
};
export const momentString = (date) => {
  return moment(date).format('YYYY-MM-DD').toString();
};
export const momentTimeStringLocal = (time) => {
  return moment(time).format('HH:mm:ss').toString();
};
export const momentsDateTime = (date, time) => {
  return `${dateFormat(date)} ${momentTimeStringLocal(time)}`;
};
export const momentDateToUTC = (date) => {
  return moment(date).utc().format('L');
};
export const momentTimeString = (time) => {
  return moment.utc(time).format('HH:mm');
};
export const momentDateString = (date) => {
  return moment.utc(date).format('L');
};
export const formatTimeWithoutAMPM = (time) => {
  return time ? moment(time).format('HH:mm') : '---';
};
export const formatTime24HourWithSeconds = (time) => {
  return time ? moment(time).format('HH:mm:ss') : '---';
};
export const getKeyLabelObject = (e) =>
  e ? e.map((val) => ({
    key: val,
    label: val,
  })) : [];
export const addDays = (str) => {
  return moment(str,'YYYY/MM/DD').add(1, 'days').format('YYYY/MM/DD');
};
export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const checkIsValidPhone = (phoneNumber,phoneRegex) => {
  const mobilePattern = new RegExp(phoneRegex);
   if(!mobilePattern.test(phoneNumber)) {
    return false;
   }
  return true;
};

export const errorToast = (errMessage) => {
  message.error(errMessage);
};
export const flattenObject = (obj, parentKey = '') => {
  const flattened = {};
  const parentKeysToInclude = ['organisation_details'];

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key]) {
      const parentIndex = parentKeysToInclude.indexOf(key);
      parentIndex > -1
        ? Object.assign(
            flattened,
            flattenObject(obj[key], parentKeysToInclude[parentIndex]),
          )
        : Object.assign(flattened, flattenObject(obj[key]));
    } else if(parentKey) {
      const keyWithParent = `${parentKey}.${key}`;
      flattened[keyWithParent] = obj[key];
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const convertCnDataToOptions = (data, type = 'shipping_label', objectType = 'consignment', rowType = 'grid') => {
  const options = [];
  const flattenedData = flattenObject(data);

  Object.keys(flattenedData).map((key) =>
    options.push({ value: key, key }),
  );
  return options;
};

export const getCodeFreeLabelType = (labelType) => {
  switch(labelType) {
    case 'STANDARD':
      return false;
    case 'SMALL':
      return true;
    default:
      return false;
  }
}
export const splitCamelCase = (s) => {
  // insert a space before all caps
  if(!s) return;
  return s.replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function(str){ return str });
}

export const transformName = (data: string) => {
  if(!data) return;
  const camelCased = data.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() });
  const splitCamelCaseChar = splitCamelCase(camelCased);
  return capitalize(splitCamelCaseChar);
}

export const  sanitizeValue = (value) => {
  return value === '' ? undefined : value;
};

export const isValidUrl = (url) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const truncateString = (data: string, length=23) => {
  if(typeof data !== 'string') return data;
  // + 3 for ...
  if(data.length <= length + 3) {
    return data;
  }
  return `${data.slice(0, (length/2))}...${data.slice(-(length/2))}`;
}

export const getDeploymentEnvironment = () => {
  const origin = window.location.hostname;
  if (
    origin.indexOf(".") > 0 &&
    GREEN_DEPLOYMENT_URLS.includes(origin.substring(origin.indexOf(".") + 1))
  ) {
    return 'green';
  } else {
    return 'blue';
  }
}

export const fetchFileDataWithUrl = async (params) => {
  const { url } = params;
  let response = await axios.get(url);
  return response.data;
};

export const convertCsvToJson = (params) => {
  const { csvText, filterHeader } = params;
  const rows = csvText.split("\n");
  if (rows.length == 0) {
    throw new Error('Invalid csv data');
  }
  const headers = rows[0].split(",").map((header) => header.trim());
  const jsonData = [];
  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split(",");
    const rowObject = {};
    for (let j = 0; j < headers.length; j++) {
      if(!filterHeader) {
        rowObject[headers[j]] = values?.[j]?.trim();
      }
      if(Array.isArray(filterHeader) && filterHeader.includes(headers[j])) {
        rowObject[headers[j]] = values?.[j]?.trim();
      }
    }
    jsonData.push(rowObject);
  }
  return jsonData;
}

export const downloadDumpMultiSheet = async (sheetData, fileName='') => {

  /*
    sheetData: [
      {
        sheetName: 'Sheet 1',
        data: [],
      },
      {
        sheetName: 'Sheet 2',
        data: [],
      }
    ]
  */
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  for (let i=0; i < sheetData.length; i++ ) {
    const {data, sheetName} = sheetData[i];
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  }
  XLSX.writeFile(wb, fileName ? fileName : `dump_js-${moment().format('DD-MMM-YYYY')}.xlsx`);
};

export const renderTime = (time) => {
  const diff = moment().diff(moment(time), 'seconds');
  if (diff === 0) {
    return 'Just now';
  }
  if (diff > 24 * 3600) {
    return moment.utc(time).local().format('DD-MM-YYYY HH:mm');
  }
  if (diff > 3600) {
    return `${Math.floor(diff / 3600)} hours ago`;
  }
  if (diff > 60) {
    return `${Math.floor(diff / 60)} minutes ago`;
  }
  return `${diff} seconds ago`;
};

export const disableMasterDataApi = () => {
  const url = new URL(window.location.href);
  const analyticTopBar = Boolean(url.searchParams.get('analyticsTopBar'));
  // window.self !== window.top => CRM inside iframe
  return analyticTopBar && window.self !== window.top;
};

export const colorsArray = [
  {
    color: '#9254DE',
    backgroundColor: '#F9F0FF',
  },
  {
    color: '#FF7A45',
    backgroundColor: '#FFF2E8',
  },
  {
    color: '#40A9FF',
    backgroundColor: '#E6F7FF',
  },
  {
    color: '#F759AB',
    backgroundColor: '#FFF0F6',
  },
  {
    color: '#52C41A',
    backgroundColor: '#F6FFED',
  },
  {
    color: '#597EF7',
    backgroundColor: '#F0F5FF',
  },
];

export const getHashCode = (string) => {
  let hashCode = 0;
  if (string.length === 0) return hashCode;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hashCode = (hashCode << 5) - hashCode + char; //eslint-disable-line no-bitwise
    hashCode |= 0; //eslint-disable-line no-bitwise
  }

  return hashCode;
};

export const getColor = (string) => {
  // this function will return a color object from Profile Avatars
  const hashCode = getHashCode(string);
  const index = hashCode % colorsArray.length;
  const assignedColorObject = colorsArray[index];

  return assignedColorObject;
};

export const getNameAbbreviation = (fullName) => {
  if (!fullName) return '';
  const words = fullName.split(' ');
  const firstWord = words[0];
  const secondWord = words[1] || '';

  const firstLetter = firstWord.charAt(0);
  const secondLetter = secondWord.charAt(0);
  return `${firstLetter}${secondLetter}`;
};

export const getTimeInWordFormat = (timeInSeconds) => {
  const time = new Date(timeInSeconds.seconds * 1000 + timeInSeconds.nanoseconds / 1e6);
  const currentTime = new Date();
  const timeDifference = currentTime - time;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutesDifference = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
  );
  let timeInWordFormat = '';
  if (daysDifference >= 1) {
    timeInWordFormat = `${daysDifference} ${
      daysDifference === 1 ? 'day' : 'days'
    }`;
  } else if (hoursDifference >= 1) {
    timeInWordFormat = `${hoursDifference} ${
      hoursDifference === 1 ? 'hour' : 'hours'
    }`;
  } else {
    timeInWordFormat = `${minutesDifference} ${
      minutesDifference === 1 ? 'minute' : 'minutes'
    }`;
  }
  return timeInWordFormat;
};

export const randomKeyGenerator = (length) => {
  const alphaNumericString =
    '0123456789abcdefghijklmnopqrstwxyzABCDEFGHIJKLMNOPQRSTWXYZ@$#*&_/%?';
  let suggestedApiKey = '';
  for (let i = 0; i < length; i++) {
    const randomKey =
      alphaNumericString[
        Math.floor(Math.random() * alphaNumericString.length)
      ];
    suggestedApiKey += randomKey;
  }
  return suggestedApiKey;
};

// FOr Pie Chart, it combines the data below threshold into 'Others
export const transformPieChartData = (chartData, thresholdPercentage) => {

  if(lodashKeys(chartData).length === 0){
    return chartData;
  }
  const totalSum = chartData.reduce((sum, item) => sum + item.y, 0);
  const threshold = ((thresholdPercentage) / 100) * totalSum;
  let sumBelowThreshold = 0;
  const finalData = chartData.filter(item => {
    if (item.y > threshold) {
      return true;
    }
    sumBelowThreshold += item.y;
    return false;
  });

  if (sumBelowThreshold > 0) {
    finalData.push({
      x: 'Others',
      y: sumBelowThreshold,
      rawData: {},
    });
  }
  return finalData;
};

export const transformBarChartData = (chartData, thresholdPercentage) => {
  if (lodashKeys(chartData).length === 0) {
    return chartData;
  }

  if (chartData.length > 1 && chartData[0].hasOwnProperty('z')) {
    const zValuesWithSum = chartData.reduce(
      (obj, item) => ((obj[item.x] = (obj[item.x] || 0) + item.y), obj),
      {},
    );
    const zValuesWithThreshold = {};
    for (const [key, value] of Object.entries(zValuesWithSum)) {
      zValuesWithThreshold[key] =
        (thresholdPercentage / 100) * zValuesWithSum[key];
    }

    const sumBelowThreshold = {};
    const finalData = chartData.filter((item) => {
      if (!item.z) {
        return false;
      }
      if (item.y > zValuesWithThreshold[item.x]) {
        return true;
      }
      sumBelowThreshold[item.x] = (sumBelowThreshold[item.x] || 0) + item.y;
      return false;
    });

    for (const [key, value] of Object.entries(sumBelowThreshold)) {
      finalData.push({
        x: key,
        y: value,
        z: 'Others',
        value: key,
      });
    }
    return finalData;
  }
  const sumBelowThreshold = {};
  const fieldValuesWithThresholds = {};
  let fieldValue = 'value';
  if (chartData.length > 1 && chartData[0].x === chartData[1].x) {
    fieldValue = 'x';
  }
  const distinctFieldValues = Array.from(
    new Set(chartData.map((item) => item[fieldValue])),
  );
  distinctFieldValues.forEach((value: any) => {
    const totalSum = chartData
      .filter((data) => data[fieldValue] === value)
      .reduce((sum, data) => sum + data.y, 0);
    const threshold = (thresholdPercentage / 100) * totalSum;

    fieldValuesWithThresholds[value] = {
      threshold,
      totalSum,
    };
  });
  const finalData = chartData.filter((item) => {
    if (item.y > fieldValuesWithThresholds[item[fieldValue]].threshold) {
      return true;
    }
    sumBelowThreshold[item[fieldValue]] =
      (sumBelowThreshold[item[fieldValue]] || 0) + item.y;
    return false;
  });

  if (Object.keys(sumBelowThreshold).length > 0) {
    for (const [key, value] of Object.entries(sumBelowThreshold)) {
      fieldValue === 'value'
        ? finalData.push({
            x: 'Others',
            y: value,
            value: key,
            rawData: {},
            name: key,
          })
        : finalData.push({
            x: key,
            y: value,
            value: 'Others',
            rawData: {},
            name: 'Others',
          });
    }
  }
  return finalData;
};

export const isReportTypeHtmlEditior = (dataMartType) => {
  return dataMartType === 'htmlEditor';
};
export const isNativeApp = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('wv') !== -1 || userAgent.indexOf('webview') !== -1 || userAgent.indexOf('lia-native-app') !== -1;
};

export const createAndDownloadCSVFileFromJSON = (jsonArray, fileName, keys) => {
  if (!jsonArray?.length) return;
  const headers = keys || Object.keys(jsonArray[0]);
  
  // Convert each object in the array to a CSV row
  const rows = jsonArray.map(obj =>
    headers.map(header => obj[header]?.toString()?.replace(/[\n\t,]/g, ' ') || '').join(',')
  );

  // Add headers as the first row
  rows.unshift(headers.join(','));

  // Join rows with newline character to form CSV string
  const csvString = rows.join('\n');

  // Create a Blob object from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a URL for the Blob object
  const url = URL.createObjectURL(blob);

  // Create a download link
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.csv`;

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up by revoking the URL object
  URL.revokeObjectURL(url);
};

export const isInAppNotificationAllowed = () => {
  return FIRESTORE_DB_ID && Object.keys(FIREBASE_CONFIG).length !== 0;
};

export const promiseAllWithConcurrency = async (promiseFns: any[], concurrencyLimit: number) => {
  const results = [];
  const errors = [];
  const executing = [];
  let index = 0;

  const executePromise = async (promiseFn, currentIndex) => {
    try {
      const result = await promiseFn();
      results[currentIndex] = result;
    } catch (error) {
      errors[currentIndex] = error;
    } finally {
      executing.splice(executing.indexOf(currentIndex), 1);
    }
  };

  while (index < promiseFns.length) {
    while (executing.length < concurrencyLimit && index < promiseFns.length) {
      executing.push(index);
      index++;
    }

    await Promise.all(
      executing.map(
        (currentIndex) => executePromise(
          promiseFns[currentIndex],
            currentIndex,
          ),
      ));
  }

  return results;
};

export const fetchMissingData = async (record, fields) => {
  const missingFields = fields.filter(field => {
    if (Array.isArray(record[field])) {
      return !record[field].length;
    }
    return !record.hasOwnProperty(field);
  });

  if (missingFields.length > 0) {
    const referenceNumberList = [record.reference_number];
    const data = await getConsignmentsExtraData({ requiredColumns: missingFields, referenceNumberList });

    missingFields.forEach(field => {
      record[field] = data.data?.[0]?.[field] || null;
    });
  }
};

export const flattendObject = (obj: any, parentKey = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], prefixedKey));
    } else {
      acc[prefixedKey] = obj[key];
    }
    return acc;
  }, {} as any);
};

export const unflattenObject =(flatObj: any) =>{
  const unflatten = (obj: any) => {
    const result = {};
    const objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
      const keys = key.split('.');
      let nestedObj: any = result;
      for (let i = 0; i < keys.length - 1; i += 1) {
        const nestedKey = keys[i];
        if (!(nestedKey in nestedObj)) {
          nestedObj[nestedKey] = {};
        }
        nestedObj = nestedObj[nestedKey];
      }
      nestedObj[keys[keys.length - 1]] = obj[key];
    });
    return result;
  };

  return unflatten(flatObj);
}

export const taskTypesMapping = {
  delivery: ['delivery'],
  pickup: [
    'pickup',
    'store_consignment_pickup',
  ],
  single_consignment_pickup: ['single_consignment_pickup'],
  job: [
    'job_service',
    'job_maintenance',
    'job_installation',
    'job_rectification',
    'job_fitment',
    'job_inspection',
    'job_ev_recharge',
    'job_cod',
  ],
};
