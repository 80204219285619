export const actions = [
    {
      key: 'debit_vallet_amount',
      name: 'Debit To Virtual Wallet',
      children: [
        {
          title: 'Debit To Virtual Wallet',
          key: 'debit_vallet_amount_single',
          name: 'Single',
          type: 'single',
          modal: 'debit_vallet_amount',
          transactionType: 'debit',
        },
        // {
        //   title: 'Bulk Debit To Virtual Wallet',
        //   key: 'bulk_debit_vallet_amount',
        //   name: 'Bulk',
        //   type: 'bulk',
        //   modal: 'bulk_debit_vallet_amount',
        //   transactionType: 'debit',
        // },
      ],
    },
    {
      key: 'credit_vallet_amount',
      name: 'Credit To Virtual Wallet',
      children: [
        {
          title: 'Credit To Virtual Wallet',
          key: 'credit_vallet_amount_single',
          name: 'Single',
          type: 'single',
          modal: 'credit_vallet_amount',
          transactionType: 'credit',
        },
        // {
        //   title: 'Bulk Credit To Virtual Wallet',
        //   key: 'bulk_credit_vallet_amount',
        //   name: 'Bulk',
        //   type: 'bulk',
        //   modal: 'bulk_credit_vallet_amount',
        //   transactionType: 'credit',
        // },
      ],
    },
    {
      key: 'credit_consignment_amount',
      name: 'Credit Against Consignment',
      children: [
        {
          title: 'Credit Against Consignment',
          key: 'credit_consignment_amount_single',
          name: 'Single',
          type: 'single',
          modal: 'credit_consignment_amount',
          transactionType: 'credit',
        },
        // {
        //   title: 'Bulk Credit Against Consignment',
        //   key: 'bulk_credit_consignment_amount',
        //   name: 'Bulk',
        //   type: 'bulk',
        //   modal: 'bulk_credit_consignment_amount',
        //   transactionType: 'credit',
        // },
      ],
    },
    {
      key: 'debit_consignment_amount',
      name: 'Debit Against Consignment',
      children: [
        {
          title: 'Debit Against Consignment',
          key: 'debit_consignment_amount_single',
          name: 'Single',
          type: 'single',
          modal: 'debit_consignment_amount',
          transactionType: 'debit',
        },
        // {
        //   title: 'Bulk Debit Against Consignment',
        //   key: 'bulk_debit_consignment_amount',
        //   name: 'Bulk',
        //   type: 'bulk',
        //   modal: 'bulk_debit_consignment_amount',
        //   transactionType: 'debit',
        // },
      ],
    },
];

export const ValletTransactionsViewControls = {
  viewControl: {
    dateFilterOptions: [
      { key: 'created_at', val: 'Created At' },
    ],
  }
}

export const transactionColumns = [
  {
    title: 'Customer Code',
    dataIndex: 'customer_code',
    key: 'customer_code',
  },
  {
    title: 'Transaction Date and Time',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transaction_type',
    key: 'transaction_type',
  },
  {
    title: 'Reference Number',
    dataIndex: 'consignment_reference_number',
    key: 'reference_number',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Transaction Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
];

export const headerMapping = {
  "CUSTOMER CODE" : 'customer_code',
  "CONSIGNMENT REFERENCE NUMBER" : 'reference_number',
  "AMOUNT" : 'amount',
  "NOTES" : 'notes',
}