import { deleteAllocationRulesExcel } from "src/api/CarrierPartner";
import { bulkUploadMasterContracts } from "src/api/pricingMaster";

export enum IncomingActions {
    GENERIC_BULK_UPLOAD = 'GENERIC_BULK_UPLOAD',
}

export const allowedViewTypesIframeOps = [
    'logistics',
    'retail',
    'domestic',
    'tms',
    'logistics_express',
    'ondemand',
]
  
export const eventToApiFuncMap = {
    rate_master_add_update_bulk: bulkUploadMasterContracts,
    rate_master_delete_bulk: deleteAllocationRulesExcel,
}