import { getAuthenticationHeaders } from 'src/api/utils';
import { IFRAME_NON_STRICT_MODE } from './../../../globals';
import { AppMetadata, OutgoingActions } from './constants';
import theme from '../../../theming/defaultTheme.json';
import { ThemeType } from 'src/theming/jssTypes';
import { API_BASE_URL } from '@src/globals'
const allMessageQueues = {};
const isAppLoaded = {};

const sendMessageToIframeWindow = (appName, message) => {
  const frameId = AppMetadata[appName].iframeId;
  const origin = IFRAME_NON_STRICT_MODE ? '*' : AppMetadata[appName].origin;
  window.frames[frameId].contentWindow.postMessage(message, origin);
};

const checkIfAppLoaded = (appName) => {
  return isAppLoaded[appName] || false;
};

export const setAppLoaded = (appName) => {
  console.log('inside set app loaded');
  isAppLoaded[appName] = true;
};

export const sendMessage = (appName, message) => {
  console.log('sendmessage', appName, message);
  if (checkIfAppLoaded(appName)) {
    sendMessageToIframeWindow(appName, message);
  } else {
    allMessageQueues[appName] = (allMessageQueues[appName] || []).concat(message);
  }
};

export const flushMessageQueue = (appName) => {
  const queue = allMessageQueues[appName] || [];
  for (const message of queue) {
    sendMessageToIframeWindow(appName, message);
  }
};

export const sendRoutingEvent = (appName, route) => {
  const stringifiedRoute = JSON.stringify({
    type: OutgoingActions.Routing,
    data: route,
  });
  sendMessage(appName, stringifiedRoute);
};

export const sendCredentials = (appName, credentials, crmTheme: ThemeType = theme) => {
  const apiBaseUrl = new URL(API_BASE_URL);
  const stringifiedCredentials = JSON.stringify({
    type: OutgoingActions.CREDENTIALS_SHARING,
    data: {
      ...credentials,
      'api-base-url': apiBaseUrl.hostname,
      'parent-url': window.location.href,
      'project-name': 'logistics',
      ...(appName === 'LIA_CO_PILOT' && { 'notification-allowed': ('Notification' in window)  && Notification?.permission === 'granted' }),
      metadata: {
        uiTheme: {
          'navbar-background': crmTheme.colors.globalHeader,
          'text-color': crmTheme.colors.textOnDarkBg,
        },
      },
    },
  });
  sendMessage(appName, stringifiedCredentials);
};
