import {
  TRIP_TRACKING_UPDATE_FILTER,
  TRIP_TRACKING_UPDATE_DATA,
  TRIP_TRACKING_UPDATE_TRIP_LIST_DATA,
  TRIP_TRACKING_ENABLE_LOADING,
  TRIP_TRACKING_DISABLE_LOADING,
  TRIP_TRACKING_RESET_FILTER,
  TRIP_TRACKING_HISTORY_RESET_FILTER,
  TRIP_TRACKING_RESET_HISTORY_DATA,
  TRIP_TRACKING_UPDATE_CACHED_MAP,
} from '../actions/constants';
import * as moment from 'moment';

const defaultState = {
  appliedFilters: {
    type: 'live',
    date: moment().format('YYYY-MM-DD'),
    currTripId : null,
    showPlannedTrip: true,
    showStrandedVehicle: false,
    tripReferenceNumber: null,
  },
  historyData: {
    hubData: {},
    tripData: {},
    routeData: [],
    workerDetails: {},
  },
  historyTripList : [],
  tripIdToGetHistoryResponseMap: {},
};

export default function (state: any = defaultState, action) {

  switch (action.type) {
    case TRIP_TRACKING_UPDATE_TRIP_LIST_DATA : {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        historyTripList: action.data,
      };
    }
    case TRIP_TRACKING_UPDATE_CACHED_MAP : {
      return {
        ...state,
        tripIdToGetHistoryResponseMap: action.data,
      };
    }
    case TRIP_TRACKING_UPDATE_DATA: {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        historyData: action.data,
      };
    }
    case TRIP_TRACKING_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    case TRIP_TRACKING_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRIP_TRACKING_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case TRIP_TRACKING_RESET_FILTER: {
      return {
        ...state,
        appliedFilters: defaultState.appliedFilters,
      };
    }
    case TRIP_TRACKING_HISTORY_RESET_FILTER: {
      return {
        ...state,
        historyData: defaultState.historyData,
        appliedFilters:
          { ...defaultState.appliedFilters, type: 'history' },
      };
    }
    case TRIP_TRACKING_RESET_HISTORY_DATA :  {
      return {
        ...state,
        historyData: defaultState.historyData,
        tripIdToGetHistoryResponseMap : {},
      };
    }
    default: return state;
  }
}
