import * as moment from 'moment';
import {
  VALLET_TRANSACTIONS_PAGINATION,
  VALLET_TRANSACTIONS_UPDATE_DATA,
  VALLET_TRANSACTION_UPDATE_FILTER,
  VALLET_RESET_PAGINATION,
  VALLET_ENABLE_LOADING,
  VALLET_DISABLE_LOADING,
} from '../actions/constants';

const defaultState = {
  isLoading: false,
  valletTransactionsData: [],
  appliedFilters: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    dateFilterType: 'created_at',
    customerCodes: [],
  },
  // More Filters component will always recive data from temp
  pagination: {
    currentPageNumber: 1,
    nextOrPrev: 'first',
    limitPerPage: 50,
  },
};

export default function (state: any = defaultState, action) {

  switch (action.type) {

    case VALLET_TRANSACTIONS_UPDATE_DATA: {
      return {
        ...state,
        valletTransactionsData: action.data,
        pagination: {
          ...state.pagination,
          isNextPresent: action.data ? action.data.is_next_present : null,
          lastDateFieldValuePrev: action.data ? action.data.last_date_field_value_prev : null,
          lastDateFieldValueNext: action.data ? action.data.last_date_field_value_next : null,
        },
      };
    }
    case VALLET_TRANSACTION_UPDATE_FILTER: {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.data,
        },
      };
    }
    case VALLET_RESET_PAGINATION: {
      return {
        ...state,
        pagination: defaultState.pagination,
      };
    }
    case VALLET_ENABLE_LOADING : {
      return {
        ...state,
        isLoading: true,
      };
    }
    case VALLET_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case VALLET_TRANSACTIONS_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.data,
        },
      };
    }
    default: return state;

  }
}
