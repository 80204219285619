import React from 'react';
import { RENDER_PERSISTENT_IFRAME_OPS } from 'src/globals';
import { connect } from 'react-redux';
import MessageHandler from './messageHandler';
import { allowedViewTypesIframeOps } from './utils';
import PersistentIframeInjector from '../PersistentIframeInjector';
import { IframeApps } from '../constants';

const OpsDashboardIframe = (props) => {
  const { viewType, appName } = props;

  return (
    <>
      {RENDER_PERSISTENT_IFRAME_OPS && allowedViewTypesIframeOps.indexOf(viewType) > -1 ? (
        <>
          <PersistentIframeInjector  appName={ IframeApps.OpsDashboard} />
          <MessageHandler appName={appName} />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { masterData } = state;
  return {
    viewType: masterData.viewType,
  };
};

export default connect(mapStateToProps)(OpsDashboardIframe);