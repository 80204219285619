import * as React from 'react';
import { Button, Input, message, Spin, Row, Col, Image } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { EyeTwoTone, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';
import Loader from '@components/common/Loader';
import { getOrganisationDetails, login, getAvailableSSOForLogin, getOidcOAuthUrl } from '@api/login';
import { isAuthenticationHeadersPresent } from '@api/utils';
import { redirectToUrl, setLocalStorageData, getURLConfigs } from '@api/utils';
import withStyles from 'react-jss';
import styles from '@components/pages/Login/Login.styles';
import * as zlib from 'react-zlib-js';
import { SSO_REDIRECT_BASE_URL, USE_ORIGIN_URL_FOR_SSO_REDIRECT } from '../../../globals';
import i18n from '../../../i18n';
import { isLanguageRTL } from '../../../utils/intl-utils';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
const shipsyLogo = require('../../../assets/images/Login/shipsyLogo.svg');
const backgroundImage = require('../../../assets/images/Login/loginBg.jpg');
const shipsyLogoMain = require('../../../assets/images/ShipsyLogo.png');
import lodash from 'lodash';
import { CryptoUtils } from 'src/utils/crypto-utils';

function LoginPage(props: any) {
  const { classes } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentLoadingBtn, setCurrentLoadingBtn] = React.useState(null);
  const [organisationDetails, setOrganisationDetails] = React.useState( {} as any );
  const [ssoDetails, setSsoDetails] = React.useState(null);
  const [disableLoginViaPassword, setDisableLoginViaPassword] = React.useState(true);
  const [loginPageImage, setLoginPageImage] = React.useState(null);
  const [urlConfigs, setURLConfigs] = React.useState({} as any);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showPoweredBy, setShowPoweredBy] = React.useState(false);

  React.useEffect(() => {
    if (isAuthenticationHeadersPresent()) {
      window.location.href = '/';
      return;
    }
    renderErrorMessage('error_message');
    fetchOrganisationDetails();
  }, []);

  const renderErrorMessage = (key) => {
    const errorMessage = window.localStorage.getItem('login_page_error_message');
    window.localStorage.removeItem('login_page_error_message');
    if ( !!errorMessage ){
      message.error(errorMessage, 5);
    }
  };

  const handleFaviconUrl = (url) => {
    LocalStorageService.set(LocalStorageKeys.__FAVICON_URL__, url);
  };

  const fetchOrganisationDetails = async () => {
    setIsLoading(true);

    const urlConfigs = getURLConfigs();
    setURLConfigs(urlConfigs);
    const responseList = await Promise.all([
      getAvailableSSOForLogin({
        organisationPrettyName: urlConfigs.organisationPrettyName,
        origin: urlConfigs.origin,
      }),
      getOrganisationDetails({
        organisationPrettyName: urlConfigs.organisationPrettyName,
        origin: urlConfigs.origin,
      }),
    ]);
    const [ssoMetadataResponse, orgDetailsResponse] = responseList;

    if (orgDetailsResponse.isSuccess) {
      setOrganisationDetails(orgDetailsResponse?.data);
      const faviconUrl = orgDetailsResponse?.data?.favicon_url || null;
      setShowPoweredBy(!orgDetailsResponse.data.hide_poweredbyshipsy_on_public_trackpage);
      if(faviconUrl){
        handleFaviconUrl(faviconUrl);
      }
      if (isAuthenticationHeadersPresent()) {
        window.location.href = redirectToUrl(orgDetailsResponse?.data);
      }
    } else {
      message.error(orgDetailsResponse.errorMessage);
    }
    if (ssoMetadataResponse.isSuccess) {
      const ssoList = ssoMetadataResponse?.data?.registeredSSO || [];
      const notAllowLoginViaPassword = (
        (ssoMetadataResponse?.data?.disableLoginViaPasswordPolicy === true)
        &&
        (ssoList.length > 0)
      );
      const loginPageImageUrl = ssoMetadataResponse?.data?.loginPageUrl;
      setSsoDetails(ssoList);
      setDisableLoginViaPassword(notAllowLoginViaPassword);
      setLoginPageImage(loginPageImageUrl);
    } else {
      message.error(ssoMetadataResponse.errorMessage);
    }
    setIsLoading(false);
  };

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onClickLogin = async (e) => {
    e.preventDefault();
    if (!username) {
      message.error('Please enter username');
      return;
    }

    if (!password) {
      message.error('Please enter password');
      return;
    }

    setCurrentLoadingBtn('loginBtn');

    const reqHeaders = {
      'Content-Type': 'application/json;charset=UTF-8',
      'organisation-pretty-name': urlConfigs.organisationPrettyName,
      'organisation-url': window.location.hostname,
    };

    const reqBody:any = { username, password };

    const encryptedPassword = await CryptoUtils.encryptRSA('authentication', password);
    if(encryptedPassword){
      reqBody.pwd = encryptedPassword;
      delete reqBody.password;
    }

    const response = await login(reqHeaders, reqBody);
    if (response.isSuccess) {
      await setLocalStorageData(organisationDetails, response.data, true);
    } else {
      message.error(response.errorMessage);
    }

    setCurrentLoadingBtn(null);
  };

  const onClickOIDCLogin = async (issuer, loadingBtnText) => {
    if (!ssoDetails?.length) {
      message.error('No SSO details found');
      return;
    }
    if (!USE_ORIGIN_URL_FOR_SSO_REDIRECT && !SSO_REDIRECT_BASE_URL) {
      message.error('Could not find redirection base url');
      return;
    }
    setCurrentLoadingBtn(loadingBtnText);

    const oidcSSO = ssoDetails.filter((sso) => sso.issuer === issuer && sso.protocol === 'oidc' && sso.is_active);
    if (!oidcSSO.length) {
      setCurrentLoadingBtn(null);
      message.error('No OIDC SSO details found');
      return;
    }
    const reqHeaders = {
      id: oidcSSO[0].id,
      organisationPrettyName: urlConfigs.organisationPrettyName,
      origin: window.location.hostname,
      referrer: window.location.hostname,
    };

    const response = await getOidcOAuthUrl(reqHeaders);
    if (response.isSuccess) {
      const authorizedUrl = response?.data?.authorizedUrl;
      const baseUrlToRedirect = USE_ORIGIN_URL_FOR_SSO_REDIRECT ? window.location.origin : SSO_REDIRECT_BASE_URL;
      window.open( `${baseUrlToRedirect}/oidcAuth?auth_url=${authorizedUrl}`, '_self' );
    } else {
      message.error(response.errorMessage || 'Google SSO is not configured!');
    }

    setCurrentLoadingBtn(null);
  };

  const generateAzureSAMLAuthnRequestParam = () => {
    return `<samlp:AuthnRequest xmlns="urn:oasis:names:tc:SAML:2.0:metadata" ID="id${Math.floor(Math.random() * 1000000000000)}" Version="2.0" IssueInstant="${new Date().toISOString()}" xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" ForceAuthn="false"><Issuer xmlns="urn:oasis:names:tc:SAML:2.0:assertion">${window.location.origin}</Issuer></samlp:AuthnRequest>`;
  };

  const encodeAzureToken = (tokenString) => {
    const deflatedBuffer = zlib.deflateRawSync(tokenString);
    const deflatedBase64String = deflatedBuffer.toString('base64');
    const SAMLRequestValue = encodeURIComponent(deflatedBase64String);
    return SAMLRequestValue;
  };

  const onClickSAMLLogin = async (issuer, loadingText) => {
    if (!ssoDetails) {
      message.error('No SSO details found');
      return;
    }
    setCurrentLoadingBtn(loadingText);

    const samlSSO = ssoDetails.filter((sso) => sso.issuer === issuer && sso.protocol === 'saml' && sso.is_active);
    if (!samlSSO || !samlSSO.length) {
      message.error('SAML SSO is not configured!');
      setCurrentLoadingBtn(null);
      return;
    }
    const ssoObj = samlSSO[0];
    let redirectionEndpoint = ssoObj?.endpoint;

    if ( !redirectionEndpoint ){
      message.error('Redirection endpoint can not be found.');
      return;
    }

    if (ssoObj?.issuer === 'azure' && ssoObj?.protocol === 'saml') {
      const AuthnRequestXML = generateAzureSAMLAuthnRequestParam();
      redirectionEndpoint = `${redirectionEndpoint}?SAMLRequest=${encodeAzureToken(AuthnRequestXML)}`;
    }

    window.open(redirectionEndpoint, '_self');

    setCurrentLoadingBtn(null);
  };

  const onClickLoginSSO = async (issuer, protocol, loadingText) => {
    switch (protocol) {
      case 'oidc':
        onClickOIDCLogin(issuer, loadingText);
        break;

      case 'saml':
        onClickSAMLLogin(issuer, loadingText);
        break;

      default:
        message.error('Invalid protocol');
    }
  };

  const LoginFormBody = () => {
    return (
      <form onSubmit={onClickLogin} className={classes.loginContainer}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flexGrow: 1 }} >
        <div>
          <img
            className={classes.logoImage}
            src={organisationDetails.logo_url || shipsyLogoMain}
          />
        </div>
        <h2 className={classes.heading}>Sign In</h2>
        {
          !disableLoginViaPassword ?
            (
              <div>
                <div className={classes.passwordInputHeader}>
                  <div>Username</div>
                </div>
                <div className={classes.inputBox}>
                  <Input
                    placeholder="Enter Username"
                    onChange={onChangeUsername}
                  />
                </div>
                <div className={classes.passwordInputHeader}>
                  <div>Password</div>
                </div>
                <div className={classes.inputBox}>
                  <Input.Password
                    placeholder="Enter Password"
                    onChange={onChangePassword}
                    iconRender={visible => (
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    )}
                  />
                </div>
                <div className={classes.forgotPasswordBtnDiv}>
                  <button type="button" onClick={() => window.location.href = '/forgot-password'}>Forgot Password?</button>
                </div>
                <div className={classes.submitButtonContainer}>
                  <Button
                    className={classes.submitButton}
                    type="primary"
                    htmlType="submit"
                    onClick={onClickLogin}
                    block
                  >
                    {currentLoadingBtn === 'loginBtn' ?
                      <Spin indicator={
                        <LoadingOutlined style={{ fontSize: 16, color: '#fff' }} spin />
                      } />
                      : 'Sign In'}
                  </Button>
                </div>
                {
                  ssoDetails?.length > 0 ?
                    <div className={classes.orDiv}>
                      <hr />
                      <span>OR</span>
                    </div>
                  : null
                }
              </div>
            ) : null
        }
        {ssoDetails?.map(sso => {
          if (!sso.is_active) {
            return null;
          }

          return (
            <div className={classes.submitButtonContainer}>
              <button type="button" className={classes.ssoBtn} onClick={() => onClickLoginSSO(sso.issuer, sso.protocol, `${sso.issuer}${sso.protocol}Btn`)}>
                {currentLoadingBtn === `${sso.issuer}${sso.protocol}Btn` ?
                  <Spin indicator={
                    <LoadingOutlined style={{ fontSize: 16, color: '#006EC3' }} spin />
                  } />
                  :
                  <>
                    <img src={sso.logo_url} alt="" />
                    <span>{lodash.get(sso, 'button_text', `Sign In With ${sso.pretty_name}`)}</span>
                  </>
                }
              </button>
            </div>
          );
        })}
      </form>
    );
  };

  const renderLoginPart = () => {
    return loginPageImage && !isMobile ? (
      <Row className={classes.roundedAndShadowBorder}  style={{ background: '#EFF5FB' }}>
        <Col span={10} className={classes.centeredContent}>{LoginFormBody()}</Col>
        <Col span={14} className={classes.centeredContent} style={{ minHeight: '480px', minWidth: '480px' }} >
          <Image
            className={classes.imageLimit}
            src={loginPageImage}
            alt={loginPageImage}
            preview={false}
          />
        </Col>
      </Row>
    ) : (
      <Row className={classes.roundedAndShadowBorder}  style={{ background: '#EFF5FB' }}>{LoginFormBody()}</Row>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <Loader zIndex={100} />
        </div>
      ) : (
        <div
          className={classes.mainContainer}
          style={{
            background: `url(${backgroundImage}) no-repeat center bottom fixed`,
            backgroundSize: 'cover',
            direction: isLanguageRTL(i18n.language) ? 'rtl' : 'ltr',
          }}
        >
          <div className={classes.topMainDiv}>
            {renderLoginPart()}
          </div>

          { showPoweredBy && <div className={classes.footerDiv}>
            <span>Powered By</span>
            <img src={shipsyLogo} alt="" />
          </div>}
        </div>
      )}
    </>
  );
}

export default withStyles(styles, { injectTheme: true })(LoginPage);
