import { BUSINESS_BOOKING_API_URL, API_BASE_URL } from '../globals';
import {
    GET_PRICING_CONTRACTS,
    CREATE_PRICING_CONTRACTS,
    DELETE_PRICING_CONTRACTS,
    BULK_UPLOAD_PRICING_CONTRACTS,
    DOWNLOAD_SAMPLE_CONTRACT,
    BULK_UPLOAD_MASTER_CONTRACTS
} from './routes';
import { GET, POST } from './apiHandler';

export const getContracts = async (params) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PRICING_CONTRACTS}`, params);
};

export const createOrEditContract = async (body) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${CREATE_PRICING_CONTRACTS}`, body);
};

export const bulkUploadContract = async (body) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_UPLOAD_PRICING_CONTRACTS}`, body);
};

export const deleteContract = async (body) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_PRICING_CONTRACTS}`, body);
};

export const downloadContract = async () => {
    return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CONTRACT}`);
};

export const bulkUploadMasterContracts = async (body) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_UPLOAD_MASTER_CONTRACTS}`, body);
}